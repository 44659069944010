import React from 'react';
import { Link } from "react-router-dom";
import Projects from "../includes/Projects";

const OUTER_CONTAINER_CLASS = "flex self-center place-items-center justify-center min-h-screen";
const INNER_CONTAINER_CLASS = "text-center align-middle ";
const HEADER_CLASS = "text-[76px] font-bold text-white";
const SUBHEADER_CLASS = "text-2xl text-white mt-3";
const LINK_CLASS = "font-bold text-white mt-5";

// Extract function for header section
function HeaderSection() {
    return (
        <h1 className={HEADER_CLASS}>Hi, I'm Elango</h1>
    );
}

// Extract function for subheader section
function SubheaderSection() {
    return (
        <p className={SUBHEADER_CLASS}>
            I'm a software engineering consultant who helps startups master their Technical SEO for content marketing.
        </p>
    );
}

// Extract function for link section
function LinkSection() {
    return (
        <p className={LINK_CLASS}>
            <Link to="/projects/alliance" className={LINK_CLASS}>Know More</Link>
        </p>
    );
}

function HomePageContent() {
    return (
        <>
            <div className={`sticky-home-content`}>
                <div className={OUTER_CONTAINER_CLASS}>
                    <div className={INNER_CONTAINER_CLASS}>
                        <HeaderSection/>
                        <SubheaderSection/>
                        <LinkSection/>
                    </div>
                </div>
            </div>
            <div className="project-section">
                <Projects/>
            </div>
        </>
    );
}

export default HomePageContent;