import React from 'react';

// Array of link data to avoid repetitive code
const links = [
    { href: "https://www.linkedin.com/in/elango-m-44250b205/", title: "Join us on Linkedin", name: "Linkedin", icon: "icon-linkedin", customClass: "linkedin-underline" },
    { href: "https://x.com/elangosurya9965", title: "Join us on Twitter", name: "Twitter", icon: "icon-twitter", customClass: "twitter-underline" },
    { href: "https://www.facebook.com/profile.php?id=100008757936473", title: "Join us on Facebook", name: "Facebook", icon: "icon-facebook", customClass: "facebook-underline" },
    { href: "https://www.instagram.com/tn29_im_elangosurya/", title: "Join us on Instagram", name: "Instagram", icon: "icon-instagram", customClass: "instagram-underline" },
];

const Footer = () => {
    const renderListItem = ({ href, title, name, icon, customClass }) => (
        <li className="leading-10 py-20 relative" key={name}>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={href}
                title={title}
                className={`text-slate-500 inline-block relative link-underlined ${customClass}`}
            >
                <span className={`text-black font-extrabold`}>{name}</span><i className={icon}></i>
            </a>
        </li>
    );

    return (
        <footer className="bg-gray-50 font-thin text-xs p-16">
            <div className="items-center uppercase text-[12px]">
            <ul className="flex items-center justify-center space-x-10">
                    {links.map(renderListItem)}
                </ul>
            </div>
            <div className="p-8 flex flex-col items-center justify-center">
                <p className="pt-4">© Elango Surya.</p>
                <p className="pb-4">All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;