import React from 'react';

function About() {
    return (
        <div className="flex items-center justify-center h-screen">
            <h1 className="text-4xl font-bold text-blue-500">About Page</h1>
        </div>
    );
}

export default About;