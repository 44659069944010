import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Header from "./includes/Header";
import Footer from "./includes/Footer";
import AllianceProjects from "./pages/projects/Alliance";


function App() {
    return (
        <Router>
            <div className="flex flex-col min-h-screen relative bg-zinc-900">
                <main className="flex-grow relative z-10">
                    <Header />
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/about" element={<About/>}/>
                        <Route path="/projects" element={<AllianceProjects/>}/>
                    </Routes>
                    <Footer/>
                </main>
            </div>
        </Router>
    );
}

export default App;