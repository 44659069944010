import { useEffect, useState, useCallback } from 'react';
import { Link } from "react-router-dom";

const navLinkClass = "text-amber-50 text-xs";

const NavLink = ({ to, children }) => (
    <Link to={to} className={navLinkClass}>{children}</Link>
);

const NavigationLinks = () => (
    <ul className="flex gap-7 justify-between"
        style={{touchAction: 'pan-y'}}>
        <li className="current selected menu-item active">
            <NavLink to="/projects">Work</NavLink>
        </li>
        <li className="menu-item">
            <NavLink to="/about">About</NavLink>
        </li>
        <li className="menu-item">
            <NavLink to="/pricing">Pricing</NavLink>
        </li>
        <li className="menu-item">
            <NavLink to="/testimonies">Testimonies</NavLink>
        </li>
        <li className="menu-item">
            <NavLink to="/book-a-project">Book a Project</NavLink>
        </li>
    </ul>
);

function Header() {
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(window.scrollY);

    const handleScroll = useCallback(() => {
        if (window.scrollY > lastScrollY && window.scrollY > 100) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
        setLastScrollY(window.scrollY);
    }, [lastScrollY]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return (
        <header className={`header py-10 px-12 items-center justify-between uppercase flex transition-all duration-1000 fixed w-full z-50 ${isVisible ? 'top-0' : '-top-full'}`}>
            <h1 className="head-logo text-white">
                <NavLink to="/">JE QUANTUM SOLUTIONS</NavLink>
            </h1>
            <nav className="main-d-nav">
                <NavigationLinks/>
            </nav>
        </header>
    );
}

export default Header;