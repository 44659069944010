import React from "react";
import homepage from "../../assets/projects/alliance/homepage-01.png";

function AllianceProjects() {
    return (
        <div className="flex items-center justify-center min-h-screen">
            <main className="py-6 px-4 sm:p-6 md:py-10 md:px-8">
                <div className="max-w-4xl mx-auto grid grid-cols-1 lg:max-w-5xl lg:gap-x-20 lg:grid-cols-2">
                    <div
                        className="relative p-3 col-start-1 row-start-1 flex flex-col-reverse rounded-lg bg-gradient-to-t from-black/75 via-black/0 sm:bg-none sm:row-start-2 sm:p-0 lg:row-start-1">
                        <h1 className="mt-1 text-lg font-semibold text-white sm:text-slate-300 md:text-2xl dark:sm:text-white">
                            Beach House in Collingwood
                        </h1>
                        <p className="text-sm leading-4 font-medium text-white sm:text-slate-500 dark:sm:text-slate-400">
                            Entire house
                        </p>
                    </div>
                    <div
                        className="grid gap-4 col-start-1 col-end-3 row-start-1 sm:mb-6 sm:grid-cols-4 lg:gap-6 lg:col-start-2 lg:row-end-6 lg:row-span-6 lg:mb-0">
                        <img
                            src={homepage}
                            alt=""
                            className="w-full h-60 object-cover rounded-lg sm:h-52 sm:col-span-2 lg:col-span-full"
                            loading="lazy"
                        />
                        <img
                            src={homepage}
                            alt=""
                            className="hidden w-full h-52 object-cover rounded-lg sm:block sm:col-span-2 md:col-span-1 lg:row-start-2 lg:col-span-2 lg:h-32"
                            loading="lazy"
                        />
                        <img
                            src={homepage}
                            alt=""
                            className="hidden w-full h-52 object-cover rounded-lg md:block lg:row-start-2 lg:col-span-2 lg:h-32"
                            loading="lazy"
                        />
                    </div>
                    <dl className="mt-4 text-xs font-medium flex items-center row-start-2 sm:mt-1 sm:row-start-3 md:mt-2.5 lg:row-start-2">
                        <dt className="sr-only">Location</dt>
                        <dd className="flex items-center text-gray-50">
                            <svg
                                width={24}
                                height={24}
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="mr-1 text-slate-400 dark:text-slate-500"
                                aria-hidden="true"
                            >
                                <path
                                    d="M18 11.034C18 14.897 12 19 12 19s-6-4.103-6-7.966C6 7.655 8.819 5 12 5s6 2.655 6 6.034Z"/>
                                <path d="M14 11a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"/>
                            </svg>
                            Collingwood, Ontario
                        </dd>
                    </dl>
                    <p className="mt-4 text-sm leading-6 col-start-1 sm:col-span-2 lg:mt-6 lg:row-start-4 lg:col-span-1 text-slate-300">
                        This sunny and spacious room is for those traveling light and looking for
                        a comfy and cosy place to lay their head for a night or two. This beach
                        house sits in a vibrant neighborhood littered with cafes, pubs,
                        restaurants and supermarkets and is close to all the major attractions
                        such as Edinburgh Castle and Arthur's Seat.
                    </p>
                </div>
            </main>
        </div>
    );
}

export default AllianceProjects;