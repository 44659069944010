import React from "react";
import atmnLogo from '../assets/projects/alliance/ATMNLogo.png';
import arLogo from '../assets/projects/alliance/Annualreportlogo.png';
import mriLogo from '../assets/projects/alliance/allianceMRIlogo.png';
import allianceLogo from '../assets/projects/alliance/svg/logomark-horizontal.svg';

const PROJECT_CARD_CLASS = "w-1/4 bg-zinc-900";
const CARD_CLASS = "card group";
const TEXT_CENTER_CLASS = "text-center relative p-56 transition-transform duration-1000 font-bold";
const FRONT_CONTENT_CLASS = "absolute top-0 bottom-0 right-0 left-0 p-8 flex items-center justify-center transition-opacity duration-100 opacity-100";
const BACK_CONTENT_CLASS = "absolute top-0 bottom-0 right-0 left-0 p-8 bg-gray-50 text-black flex items-center justify-center transition-opacity duration-100 opacity-0 group-hover:opacity-80";

const CardContent = ({ contentClass, children }) => (
    <div className={contentClass}>
        {children}
    </div>
);

const FlipCard = ({ FrontContent, BackContent }) => {
    return (
        <div className={CARD_CLASS}>
            <div className={TEXT_CENTER_CLASS}>
                <CardContent contentClass={FRONT_CONTENT_CLASS}>
                    {FrontContent}
                </CardContent>
                <CardContent contentClass={BACK_CONTENT_CLASS}>
                    {BackContent}
                </CardContent>
            </div>
        </div>
    );
};

const ProjectCard = ({ projectName, projectDetails, image, projectDesc }) => {
    return (
        <div className={`${PROJECT_CARD_CLASS}`}>
            <FlipCard
                FrontContent={
                    <div>
                        <img
                            alt={projectName}
                            loading="lazy"
                            width={184}
                            height={36}
                            decoding="async"
                            data-nimg={1}
                            style={{ color: "transparent" }}
                            src={image}
                        />
                    </div>
                }
                BackContent={
                    <div>
                        {projectDetails}
                        <p className="text-[7px] lg:text-sm">{projectDesc}</p>
                    </div>
                }
            />
        </div>
    );
};

const Projects = () => {

    const loremDescription = "Lorem Ipsum is simply dummy text of the printing and typesetting industry...";

    const projectData = [
        {
            name: "The Alliance",
            image: allianceLogo,
            details: "Details of Project 1",
            description : loremDescription
        },
        {
            name: "Church ATMN",
            image: atmnLogo,
            details: "Details of Project 2",
            description : loremDescription
        },
        {
            name: "Church AR",
            image: arLogo,
            details: "Details of Project 3",
            description : loremDescription
        },
        {
            name: "Church MRI",
            image: mriLogo,
            details: "Details of Project 4",
            description : loremDescription
        }
    ];

    return (
        <div className="mx-auto flex flex-row items-center justify-center">
            {projectData.map((project, index) => (
                <ProjectCard
                    key={index}
                    projectName={project.name}
                    image={project.image}
                    projectDetails={project.details}
                    projectDesc={project.description}
                />
            ))}
        </div>
    );
};

export default Projects;